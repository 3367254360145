import { useTranslation } from 'react-i18next';

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import ProjectCard from "../Projects/ProjectCards";


// Title images
import bahcelievlerKonutTitlePhoto from "../Projects/img/bahcelievlerKonutTitlePhoto.jpg";
import dbnInsaatOfficeTitlePhoto from "../Projects/img/dbnInsaatOfficeTitlePhoto.jpg";
import hillBoutiqueTitlePhoto from "../Projects/img/hillBoutiqueTitlePhoto.jpg";

import projectsData from "./HomeProjects.json";

function HomeProjects() {
  const { t } = useTranslation();

  const images = {
    bahcelievlerKonutTitlePhoto,
    dbnInsaatOfficeTitlePhoto,
    hillBoutiqueTitlePhoto,
  };

  const navigate = useNavigate();


  return (

    <div>
      <Container fluid className="project-section">

        <Container>
          <h1 className="project-heading mb-5">
            {t("homeProjectsPageTitle")}
          </h1>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            {projectsData.map((projects) => {
              return (
                <Col key={projects.title} sm={12} md={6} xxl={4} className="project-card">
                  <ProjectCard
                    imgPath={images[projects.titleImageName]}
                    isBlog={false}
                    title={t(projects.name)}
                    description={projects.description}
                    city={projects.city}
                    town={projects.town}
                    name={projects.name}
                  />
                </Col>
              )

            })}



          </Row>
          <Row className='justify-content-center'>
            <button style={{width:"200px"}} className='btn btn-primary mt-2 mb-2'
             onClick={()=>navigate("/project")}>
              {t("homeProjectsPageButton")}
              </button>
          </Row>
        </Container>
      </Container>

    </div>


  );
}

export default HomeProjects;
