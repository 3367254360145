import { useTranslation } from 'react-i18next';
import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";


import { FaPinterest,FaInstagram,FaMapMarkedAlt,FaPhoneAlt,FaMailBulk } from "react-icons/fa";



function Contact() {
  const { t } = useTranslation();


  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{t('contactHelmetTitle')}</title>
          <meta name="description" content="iletişim açıklama"></meta>
        </Helmet>
      </HelmetProvider>


      <Row className="startPageImgs">
        <div className="contactStartPageParallax">
          <p className="contactPageStartPageTitle">{t("contactPageStartPageTitle1")} <br /> {t("contactPageStartPageTitle2")}<sup>®</sup> </p>
        </div>
      </Row>

      <div className="container resume-section">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center mb-5">
            <h2 style={{ color: "black" }} className="heading-section">{t("contactPageContactFormH1")}</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch contactFormContactArea">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-4">{t("contactPageContactFormMessageText")}</h3>
                    <div id="form-message-warning" className="mb-4"></div>
                    <form action="https://submit-form.com/30ifuachh" method="POST" id="contactForm" name="contactForm" className="contactForm" noValidate="novalidate">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label" htmlFor="name">{t("contactPageContactFormName")}</label>
                            <input type="text" className="form-control" name="name" id="name" placeholder={t("contactPageContactFormName")} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label" htmlFor="email">{t("contactPageContactFormEmail")}</label>
                            <input type="email" className="form-control" name="email" id="email" placeholder={t("contactPageContactFormEmail")} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="subject">{t("contactPageContactFormPhone")}</label>
                            <input type="text" className="form-control" name="phone" id="subject" placeholder={t("contactPageContactFormPhone")} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="#">{t("contactPageContactFormMessage")}</label>
                            <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder={t("contactPageContactFormMessage")}></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input type="submit" value={t("contactPageContactFormSendButton")} className="btn btn-primary contactFormSendButton" />
                            <div className="submitting"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                  <div className="contactFormInfoArea info-wrap w-100 p-md-5 p-4">
                    <h3>{t("contactPageContactFormInfoAreaH1")}</h3>
                    <p className="mb-4">{t("contactPageContactFormInfoAreaMessage")}</p>
                    <div className="dbox w-100 d-flex align-items-start">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaMapMarkedAlt size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaAddressSpan")}</span> Kızılırmak, 06510 Çankaya/Ankara</p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaPhoneAlt size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaPhoneNoSpan")}</span> <a href="tel://05526447143">0552-644-7143</a></p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaMailBulk size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaEmailSpan")}</span> <a href="mailto:melinteriordesign@outlook.com">melinteriordesign@outlook.com</a></p>
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center">
                      <div className="w-100 icon d-flex align-items-center justify-content-evenly">
                      <a href="https://www.instagram.com/melinteriordesign_?igsh=dmtncWVlaGd6Z2pz" className="me-4 text-reset" target='_blank' rel='noreferrer'>
                      <FaInstagram className="contactFormInfoIcon instagramIcon" />
                      </a>
                      <a href="https://tr.pinterest.com/melinteriordesignstudio/" className="me-4 text-reset" target='_blank' rel='noreferrer'>
                      <FaPinterest className="contactFormInfoIcon pinterestIcon" />
                      </a>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12241.578356511134!2d32.808903!3d39.910185!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d348b2a8a8bb1b%3A0x6fc94f4a1deecb8a!2sYDA%20Center!5e0!3m2!1str!2str!4v1709886136597!5m2!1str!2str"
              width="100%" height="450" title="Melinteriordesign Konum Harita">
            </iframe>
          </Col>
        </Row>

      </div>
    </div>




  );
}

export default Contact;
