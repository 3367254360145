import { useTranslation } from 'react-i18next';
import React from "react";


function HomeParallax2() {
    const { t } = useTranslation();
    return (
        <div className="homepageparallax2 textFont">
        <p className="homePageParallaxText2"> {t("homePageParallaxText2")}</p>
      </div>
    );
}

export default HomeParallax2;

