import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

function ProjectCards(props) {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleDetailsClick = (name) => {
    // Detaylar düğmesine tıklandığında yönlendirme yap
    navigate(`/project/${name}`);
  };


  return (
    <Card className="project-card-view rounded"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <div className="projectInfoDiv">
          <h3 className="headerFont">{props.title}</h3>
          <p className="textFont">{`${props.city}/${props.town}`}</p>
          <Button className="mt-2 projectInfoDivDetailButton textFont" onClick={() => {
            handleDetailsClick(props.name)
          }}>
            {t("projectCardsPageDetailButton")}
          </Button>
        </div>
      )}
      <div className="projectCardImgDiv">
      <LazyLoadImage className="projectCardImg" src={props.imgPath}  style={{ filter: hovered ? 'brightness(40%)' : 'brightness(100%)' }} />
      </div>
      
      <Card.Body className="projectCardTextDiv">
        <div className="projectCardTitleTextDiv">
        <span className="projectCardTitle">{props.title}</span>
        </div>
        

      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
