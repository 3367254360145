import { useTranslation } from 'react-i18next';
import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


function StyleDesign() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (

    <div>
      <HelmetProvider>
        <Helmet>
          <title>{t("stylequizPageHelmetTitle")}</title>
          <meta name="description" content={t("stylequizPageHelmetDesc")}></meta>
        </Helmet>
      </HelmetProvider>


      <Row className="startPageImgs">
        <div className="stylequizStartPageParallax">
          <h1 className="stylequizPageStartPageTitle">
            {t("stylequizPageStartPageTitle")}<br />
            <p className="quizPageStartPageDescription">{t("stylequizPageStartPageDesc")}</p>
            <button onClick={() => navigate("/defineyourstyle/quiz")} className="quizPageStartPageButton">{t("stylequizPageStartPageButton")}</button>
          </h1>
        </div>
      </Row>
    </div>


  );
}

export default StyleDesign;
