// Çeviri
import { useTranslation } from 'react-i18next';
// React
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// Helmet Seo
import { HelmetProvider, Helmet } from 'react-helmet-async';
// Fotograf ve video
import mimarVideo2 from "../../Assets/mimarvideo2.mp4";
import homepagelabelphoto1 from "../../Assets/homepagelabelphoto1.jpeg";
// pages
import Home2 from "./Home2";
import Home3 from "./Home3";
import HomeParallax1 from "./HomeParallax1";
import HomeParallax2 from "./HomeParallax2";
import AboutSectors from "../About/AboutSectors";
import Type from "./Type";
import HomeProjects from "./HomeProjects";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import vigInternationalPhoto2 from "../Projects/img/vigInternationalPhoto (2).jpg";
import arkasHoldingTitlePhoto from "../Projects/img/arkasHoldingTitlePhoto.jpg";
import bahcelievlerKonutTitlePhoto from "../Projects/img/bahcelievlerKonutTitlePhoto.jpg";
import coffeeKioskPhoto1 from "../Projects/img/coffeeKioskPhoto (1).jpg";
import dbnInsaatOfficeTitlePhoto from "../Projects/img/dbnInsaatOfficeTitlePhoto.jpg";
import gazselElektromekanikPhoto2 from "../Projects/img/gazselElektromekanikPhoto (2).jpg";
import germanyHukukOfficePhoto1 from "../Projects/img/germanyHukukOfficePhoto (1).jpg";
import germanyLoftDaireTitlePhoto from "../Projects/img/germanyLoftDaireTitlePhoto.jpg";
import hillBoutiqueTitlePhoto from "../Projects/img/hillBoutiqueTitlePhoto.jpg";
import incekVillaTitlePhoto from "../Projects/img/incekVillaTitlePhoto.jpg";
import manolyaMobilyaMağazaPhoto5 from "../Projects/img/manolyaMobilyaMağazaPhoto (5).jpg";
import mevaVillalariPhoto13 from "../Projects/img/mevaVillalariPhoto (13).jpg";
import normTechnologyTitlePhoto from "../Projects/img/normTechnologyTitlePhoto.jpg";
import otokentOtoEkspertizPhoto4 from "../Projects/img/otokentOtoEkspertizPhoto (4).jpg";
import pasifikEurasiaTitlePhoto from "../Projects/img/pasifikEurasiaTitlePhoto.jpg";
import piyapInsaatPhoto10 from "../Projects/img/piyapInsaatPhoto (10).jpg";
import urlaMustakilKonutPhoto3 from "../Projects/img/urlaMustakilKonutPhoto (3).jpg";
import vanKahvaltiPhoto6 from "../Projects/img/vanKahvaltiPhoto (6).jpg";
import yasamkentVillaPhoto18 from "../Projects/img/yasamkentVillaPhoto (18).jpg";



function Home() {
  const { t } = useTranslation();


  const imgStyle = {
    width: '100%',
    height: '100vh',
    objectFit: 'cover'
  }


  const slideImages = [
    { url: vigInternationalPhoto2 },
    { url: arkasHoldingTitlePhoto },
    { url: bahcelievlerKonutTitlePhoto },
    { url: coffeeKioskPhoto1 },
    { url: dbnInsaatOfficeTitlePhoto },
    { url: gazselElektromekanikPhoto2 },
    { url: germanyHukukOfficePhoto1 },
    { url: germanyLoftDaireTitlePhoto },
    { url: hillBoutiqueTitlePhoto },
    { url: incekVillaTitlePhoto },
    { url: manolyaMobilyaMağazaPhoto5 },
    { url: mevaVillalariPhoto13 },
    { url: normTechnologyTitlePhoto },
    { url: otokentOtoEkspertizPhoto4 },
    { url: pasifikEurasiaTitlePhoto },
    { url: piyapInsaatPhoto10 },
    { url: urlaMustakilKonutPhoto3 },
    { url: vanKahvaltiPhoto6 },
    { url: yasamkentVillaPhoto18 }
  ];

  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>{t("homePageHelmetTitle")}</title>
          <meta name="description" content={t("homePageHelmetDesc")}></meta>
        </Helmet>
      </HelmetProvider>


      <Container fluid className="home-section p-0" style={{zIndex:"0"}} id="home">


        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <img src={slideImage.url} style={imgStyle} alt={`slide-${index}`} />
              </div>

          ))}
        </Slide>


        <Container className="home-content">

          <Row className='homePageStartRow'>
            <Col md={12} className="home-header text-center p-0">

              <div style={{ fontSize: "24px" }}>
                <Type />
              </div>

              <h1 style={{ paddingBottom: 15 }} className="heading homeStartPageTitle">
                {t("homePageStartPageNormalTitle")}{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>


            </Col>
          </Row>
        </Container>

      </Container>

      <Home2 />
      <HomeParallax1 />
      <AboutSectors />
      <div style={{ height: "80vh" }}>
        <LazyLoadImage style={{ objectFit: "cover" }} width={"100%"} height={"100%"} src={homepagelabelphoto1} alt='Modern Tasarım Restorant'/>
      </div>
      <HomeProjects></HomeProjects>
      <HomeParallax2 />
      <Home3 />
    </section>
  );
}

export default Home;
