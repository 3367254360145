import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  SiArchicad,
  SiRhinoceros,
  SiSketchup, 
  SiCoronarenderer,
  SiAdobephotoshop , 
} from "react-icons/si";
import vRayLogo from "../../Assets/V-Ray.png"

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      <img width="72" height="72" src="https://img.icons8.com/ios-filled/100/autodesk-autocad.png" alt="autodesk-autocad"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiArchicad />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRhinoceros  />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSketchup />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img width="72" height="72" src="https://img.icons8.com/badges/96/3ds-max.png" alt="3ds-max"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img width="72" height="72" src="https://img.icons8.com/fluency-systems-filled/96/lumion.png" alt="lumion"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img width="150" src={vRayLogo} alt="vRay"></img>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCoronarenderer />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobephotoshop  />
      </Col>
    </Row>
  );
}

export default Techstack;
