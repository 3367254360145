// Çeviri
import { useTranslation } from 'react-i18next';
// React
import React from "react";
import { Container, Row, Col } from "react-bootstrap";



function Home2() {
  const { t } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {t("home2PageTitle")}
            </h1>
            <p className="home-about-body textFont">
              {t("home2PageDesc1")}
              <br />
              <br />
              {t("home2PageDesc2")}
              <br />
              <br />
              {t("home2PageDesc3")}
              <br />
              <br />
              {t("home2PageDesc4")}
              <br />
              <br />
              <i>
                <b className="purple">   {t("home2PageDesc5")}</b>
              </i>
            </p>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
