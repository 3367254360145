import React from "react";
import { createRoot } from "react-dom/client"; // Doğru import yolu
import "./index.css";
import App from "./App";
import i18n from "./i18n";



const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

