import { useTranslation } from 'react-i18next';
import React from "react";
import { Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import ofisFoto from "../../Assets/otokentOtoEkspertizPhoto (10).jpg";
import restoranFoto from "../../Assets/restoranFoto.jpg";
import kahveDukkaniFoto from "../../Assets/kahvedukkaniphoto.jpg";
import MagazaFoto from "../../Assets/hillBoutiquePhoto (7).jpg";
import acikAlanFoto from "../../Assets/acikAlanFoto.jpg";
import danismanlikFoto from "../../Assets/danismanlikFoto.jpg"; 

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

function AboutSectors() {
    const { t } = useTranslation();
    const navigate = useNavigate();

   const sectorsRouterToProject = (()=>{
        navigate("/project");
    })

    return (
        <Row className="aboutSectorsBannerRow">
            <div className="aboutSectorsRowBox container">

            <div style={{ marginBottom:"30px" }}>
                <h1 style={{ color: "black" ,marginBottom:"50px"}}>{t("AboutPageSectorsTitle")}</h1>
            </div>

            <div className="aboutSectorsImageDiv textFont" style={{ height: "95%" }}>
                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard">
                    <LazyLoadImage className="aboutSectorsImage card-img-top"
                     src={ofisFoto} alt='iç mimari ofis tasarımları'/>
                     <div className="aboutSectorImageText">{t("AboutPageSectorsOfficeDesign")}</div>
                </div>

                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard" >
                    <LazyLoadImage className="aboutSectorsImage card-img-top"
                     src={restoranFoto} alt='iç mimari restoran & bar tasarımları'/>
                      <div className="aboutSectorImageText">{t("AboutPageSectorsRestourantDesign")}</div>
                </div>

                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard">
                    <LazyLoadImage className="aboutSectorsImage card-img-top" 
                     src={kahveDukkaniFoto} alt='iç mimari kahve dükkanı tasarımları'/>
                    <div className="aboutSectorImageText">{t("AboutPageSectorsCoffeeDesign")}</div>
                </div>

                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard">
                    <LazyLoadImage className="aboutSectorsImage card-img-top"
                     src={MagazaFoto} alt='iç mimari mağaza tasarımları'/>
                    <div className="aboutSectorImageText">{t("AboutPageSectorsShoppingDesign")}</div>
                </div>

                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard" >
                    <LazyLoadImage className="aboutSectorsImage card-img-top" 
                    src={acikAlanFoto} alt='iç mimari açık alan tasarımları'/>
                    <div className="aboutSectorImageText">{t("AboutPageSectorsPublicDesign")}</div>
                </div>

                <div onClick={sectorsRouterToProject} className="col-lg-4 col-md-4 col-sm-6 aboutSectorsCard bsoldcard" >
                    <LazyLoadImage className="aboutSectorsImage card-img-top" 
                     src={danismanlikFoto} alt='iç mimari danışmanlık hizmeti'/>
                    <div className="aboutSectorImageText aboutSectorLastImageText">{t("AboutPageSectorsConsultancy")}</div>
                </div>
            </div>

            </div>
       


        </Row>
    );
}

export default AboutSectors;
