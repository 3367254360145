import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from "react-router-dom";

import { FaInstagram, FaPinterest, FaPhoneAlt, FaMapMarkedAlt, FaMailBulk } from "react-icons/fa";


import logo from "../Assets/melinteriordesignlogo.png";

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <footer className="text-center text-lg-start footerArea">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>{t("footerHeaderSpan")}</span>
        </div>
        <div>
          <a href="https://www.instagram.com/melinteriordesign_?igsh=dmtncWVlaGd6Z2pz" className="me-4 text-reset" target='_blank' rel='noreferrer'>
            <FaInstagram size="20px" className='instagramIcon'/>
          </a>
          <a href="https://tr.pinterest.com/melinteriordesignstudio/" className="me-4 text-reset" target='_blank' rel='noreferrer'>
            <FaPinterest size="20px" className='pinterestIcon'/>
          </a>
        </div>
      </section>
      <section className="">
        <div className="container text-center text-lg-start mt-5">
          <div className="row mt-3">
            <div className="col-lg-4 col-xl-3 mx-auto mb-4">
              <img onClick={()=>navigate("/")} width="250px" src={logo} alt='melinteriordesign logo' />
            </div>
            <div className="col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("footerProjectAreaTitle")}</h6>

              <p onClick={() => navigate("/project")} className="text-reset footer-navigate">
                {t("footerProjectAreaProject1")}
              </p>


              <p onClick={() => navigate("/project")} className="text-reset footer-navigate">
                {t("footerProjectAreaProject2")}
              </p>


              <p onClick={() => navigate("/project")} className="text-reset footer-navigate">
                {t("footerProjectAreaProject3")}
              </p>

            </div>
            <div className="col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("footerLinksAreaTitle")}</h6>

              <p onClick={() => navigate("/")} className="text-reset footer-navigate">
                {t("footerLinksHomepage")}
              </p>


              <p onClick={() => navigate("/about")} className="text-reset footer-navigate">
                {t("footerLinksWeUs")}
              </p>


              <p onClick={() => navigate("/project")} className="text-reset footer-navigate">
                {t("footerLinksProjects")}
              </p>


              <p onClick={() => navigate("/contact")} className="text-reset footer-navigate">
                {t("footerLinksContact")}
              </p>


              <p onClick={() => navigate("/defineyourstyle")} className="text-reset footer-navigate">
                {t("footerLinksStilDesign")}
              </p>

            </div>
            <div className="col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">{t("footerContactAreaTitle")}</h6>
              <p>
                <FaMapMarkedAlt size="20px" className='me-2'/>  Kızılırmak, 06510 Çankaya/Ankara
              </p>
              <p>
                <FaPhoneAlt size="20px" className='me-2'/> <a className='footer-navigate text-reset' href="tel://05526447143">0552-644-7143</a>
              </p>
              <p>
                <FaMailBulk size="20px" className='me-2'/> <a className='footer-navigate text-reset' href="mailto:melinteriordesign@outlook.com">melinteriordesign@outlook.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        {t("footerPrivacyAreaTitle")}
        <span className="text-reset footer-navigate" onClick={()=>navigate("/")}>
          Melinteriordesign
        </span>
      </div>
    </footer>


  );
}