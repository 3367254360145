import { useTranslation } from 'react-i18next';
import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  const { t } = useTranslation();
  return (
    <Typewriter
      options={{
        strings: [
          t("homePageStartPageTypeWriter1"),
          t("homePageStartPageTypeWriter2"),
          t("homePageStartPageTypeWriter3"),
          t("homePageStartPageTypeWriter4"),
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
