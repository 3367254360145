// Çeviri
import { useTranslation } from 'react-i18next';
// React
import React, { useEffect, useRef } from "react";

// Sanal tur
import { Viewer } from '@photo-sphere-viewer/core';

// Fotograf
import panoraImg2 from "../../Assets/panoraimg5.jpeg";
import loaderGif from "../../Assets/loader.gif";


function Home3() {
    const { t } = useTranslation();
    const viewerRef = useRef(null);
    let viewer = null; // Viewer'ı bir değişkene atayın

    useEffect(() => {
        viewer = new Viewer({
            container: viewerRef.current,
            panorama: panoraImg2,
            loadingImg: loaderGif,
            mousewheelCtrlKey: true,
            defaultYaw: '130deg',
            navbar: 'zoom move gallery caption fullscreen',
        });

        return () => {
            viewer && viewer.destroy(); // Temizleme fonksiyonunda Viewer'ı yok edin
        };
    }, []);


    return (
        <div className="mt-5 mb-5">
        <div className="mb-5">
            <h1>{t("home3PageTitle")}</h1>
        </div>
        <div ref={viewerRef} style={{ width: "100vw", height: "70vh" }}></div>
        </div>
    );
}

export default Home3;




// // Çeviri
// import { useTranslation } from 'react-i18next';
// // React
// import React, { useEffect, useRef } from "react";

// // Sanal tur
// import { Viewer } from '@photo-sphere-viewer/core';
// import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
// import { GalleryPlugin } from '@photo-sphere-viewer/gallery-plugin';
// import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
// import '@photo-sphere-viewer/markers-plugin/index.css';
// import '@photo-sphere-viewer/gallery-plugin/index.css';
// import '@photo-sphere-viewer/virtual-tour-plugin/index.css';

// // Fotograf ve video
// import panoraImg1 from "../../Assets/panoraimg3.jpeg";
// import panoraImg2 from "../../Assets/panoraimg5.jpeg";

// const baseUrl = 'https://photo-sphere-viewer-data.netlify.app/assets/';
// const caption = 'Müstakil Villa <b>&copy; Melinteriordesign</b>';



// function Home3() {
//     const { t } = useTranslation();
//     const viewerRef = useRef(null);

//     useEffect(() => {
//         const viewer = new Viewer({
//             container: viewerRef.current,
//             loadingImg: baseUrl + 'loader.gif',
//             touchmoveTwoFingers: true,
//             mousewheelCtrlKey: true,
//             defaultYaw: '130deg',
//             navbar: 'zoom move gallery caption fullscreen',

//             plugins: [
//                 MarkersPlugin,
//                 [GalleryPlugin, {
//                     thumbnailSize: { width: 100, height: 100 },
//                 }],
//                 [VirtualTourPlugin, {
//                     positionMode: 'gps',
//                     renderMode: '3d',
//                 }],
//             ],
//         });

//         const virtualTour = viewer.getPlugin(VirtualTourPlugin);

//         const markerLighthouse = {
//             id: 'marker-1',
//             image: baseUrl + 'pictos/pin-red.png',
//             tooltip: 'Müstakil Villa',
//             size: { width: 32, height: 32 },
//             anchor: 'bottom center',
//             gps: [-80.155973, 28.666601, 29 + 3],
//         };

//         virtualTour.setNodes([
//             {
//                 id: '1',
//                 panorama: panoraImg1,
//                 thumbnail: panoraImg1,
//                 name: 'One',
//                 caption: `[Salon] ${caption}`,
//                 links: [{ nodeId: '2' }],
//                 markers: [markerLighthouse],
//                 gps: [-80.156479, 25.666725, 3],
//                 panoData: { poseHeading: 327-55 },
//             },
//             {
//                 id: '2',
//                 panorama: panoraImg2,
//                 thumbnail: panoraImg2,
//                 name: 'Two',
//                 caption: `[Mutfak] ${caption}`,
//                 links: [{ nodeId: '1' }],
//                 markers: [markerLighthouse],
//                 gps: [-80.156168, 25.666623, 3],
//                 panoData: { poseHeading: 318 },
//             },
//         ], '1');
//     }, []);

//     return (
//         <div className="mt-5 mb-5">
//         <div className="mb-5">
//             <h1>{t("home3PageTitle")}</h1>
//         </div>
//         <div ref={viewerRef} style={{ width: "100vw", height: "70vh" }}></div>
//         </div>
//     );
// }

// export default Home3;

