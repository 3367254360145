import { useTranslation } from 'react-i18next';
import React from "react";
import Card from "react-bootstrap/Card";
import melisFoto from "../../Assets/melisfoto.jpeg";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

function AboutCard() {
  const { t } = useTranslation();
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <div className='row'>
          <div className='col-md-8'>
          <blockquote className="aboutCardPageWhoWeAreTextArea blockquote mb-0">
          <br/>
          <p>{t("aboutPageWhoWeAreTextTitle")}</p>
          <p className="aboutPageWhoWeAreText">
          {t("aboutPageWhoWeAreText")}
         
              <br/>
              <br/>
              <span className="purple">{t("aboutPageWhoWeAreTextSpan")}</span>
          
          </p>

        </blockquote>
          </div>
          <div className='col-md-4'>
    <LazyLoadImage className='aboutCardPageWhoWeAreImg' width={"100%"} src={melisFoto} alt='melinteriordesign kurucu fotoğrafı'/>
          </div>
        </div>
       
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
