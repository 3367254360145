import { useTranslation } from 'react-i18next';
import React from "react";


function HomeParallax1() {
    const { t } = useTranslation();
    return (
        <div className="parallax textFont">
        <p className="homePageParallaxText1"> {t("homePageParallaxText1")}</p>
      </div>
    );
}

export default HomeParallax1;

