import React from "react";
import { Col, Row } from "react-bootstrap";


function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      <img width="100" height="100" src="https://img.icons8.com/ios-filled/100/360-view--v3.png" alt="360 Sanal Tur"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img width="64" height="64" src="https://img.icons8.com/external-line512-zulfa-mahendra/64/external-vr-metaverse-line512-zulfa-mahendra.png"
       alt="İç mimarlık VR Metaverse"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img width="100" height="100" src="https://img.icons8.com/tes/100/experimental-3d-tes.png" alt="İç mimari 3D"/>
      </Col>
    </Row>
  );
}

export default Toolstack;
