import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Col, Container, Row } from 'react-bootstrap';

import { FaPinterest,FaInstagram,FaMapMarkedAlt,FaPhoneAlt,FaMailBulk } from "react-icons/fa";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

// livingroom

import bohemian_livingroom from "./img/bohemian_livingroom.jpg";
import country_livingroom from "./img/country_livingroom.jpg";
import industrial_livingroom from "./img/industrial_livingroom.jpg";
import minimalist_livingroom from "./img/minimalist_livingroom.jpg";
import modern_livingroom from "./img/modern_livingroom.jpg";
import rustic_livingroom from "./img/rustic_livingroom.jpg";
import scandinavian_livingroom from "./img/scandinavian_livingroom.jpg";
import traditional_livingroom from "./img/traditional_livingroom.jpg";

// sittingroom

import bohemian_sittingroom from "./img/bohemian_sittingroom.jpg";
import country_sittingroom from "./img/country_sittingroom.jpg";
import industrial_sittingroom from "./img/industrial_sittingroom.jpg";
import minimalist_sittingroom from "./img/minimalist_sittingroom.jpg";
import modern_sittingroom from "./img/modern_sittingroom.jpg";
import rustic_sittingroom from "./img/rustic_sittingroom.jpg";
import scandinavian_sittingroom from "./img/scandinavian_sittingroom.jpg";
import traditional_sittingroom from "./img/traditional_sittingroom.jpg";

// bedroom
import bohemian_bedroom from "./img/bohemian_bedroom.jpg";
import country_bedroom from "./img/country_bedroom.jpg";
import industrial_bedroom from "./img/industrial_bedroom.jpg";
import minimalist_bedroom from "./img/minimalist_bedroom.jpg";
import modern_bedroom from "./img/modern_bedroom.jpg";
import rustic_bedroom from "./img/rustic_bedroom.jpg";
import scandinavian_bedroom from "./img/scandinavian_bedroom.jpg";
import traditional_bedroom from "./img/traditional_bedroom.jpg";

// childroom
import bohemian_childroom from "./img/bohemian_childroom.jpg";
import country_childroom from "./img/country_childroom.jpg";
import industrial_childroom from "./img/industrial_childroom.jpg";
import minimalist_childroom from "./img/minimalist_childroom.jpg";
import modern_childroom from "./img/modern_childroom.jpg";
import rustic_childroom from "./img/rustic_childroom.jpg";
import scandinavian_childroom from "./img/scandinavian_childroom.jpg";
import traditional_childroom from "./img/traditional_childroom.jpg";

// kitchen

import bohemian_kitchen from "./img/bohemian_kitchen.jpg";
import country_kitchen from "./img/country_kitchen.jpg";
import industrial_kitchen from "./img/industrial_kitchen.jpg";
import minimalist_kitchen from "./img/minimalist_kitchen.jpg";
import modern_kitchen from "./img/modern_kitchen.jpg";
import rustic_kitchen from "./img/rustic_kitchen.jpg";
import scandinavian_kitchen from "./img/scandinavian_kitchen.jpg";
import traditional_kitchen from "./img/traditional_kitchen.jpg";

// terrace

import bohemian_terrace from "./img/bohemian_terrace.jpg";
import country_terrace from "./img/country_terrace.jpg";
import industrial_terrace from "./img/industrial_terrace.jpg";
import minimalist_terrace from "./img/minimalist_terrace.jpg";
import modern_terrace from "./img/modern_terrace.jpg";
import rustic_terrace from "./img/rustic_terrace.jpg";
import scandinavian_terrace from "./img/scandinavian_terrace.jpg";
import traditional_terrace from "./img/traditional_terrace.jpg";

// toilet

import bohemian_toilet from "./img/bohemian_toilet.jpg";
import country_toilet from "./img/country_toilet.jpg";
import industrial_toilet from "./img/industrial_toilet.jpg";
import minimalist_toilet from "./img/minimalist_toilet.jpg";
import modern_toilet from "./img/modern_toilet.jpg";
import rustic_toilet from "./img/rustic_toilet.jpg";
import scandinavian_toilet from "./img/scandinavian_toilet.jpg";
import traditional_toilet from "./img/traditional_toilet.jpg";

// Result photo

import quizResultBohemImg from "./img/quizresultbohemimg.jpeg";
import quizResultCountryImg from "./img/quizresultcountryimg.jpg";
import quizResultIndustrialImg from "./img/quizresultindustrialimg.jpg";
import quizResultMinimalistImg from "./img/quizresultminimalistimg.jpg";
import quizResultModernImg from "./img/quizresultmodernimg.jpg";
// import quizResultRusticImg from "./img/quizresultrusticimg.jpg";
import quizResultScandinavianImg from "./img/quizresultscandinavianimg.jpg";
import quizResultTraditionalImg from "./img/quizresulttraditionalimg.jpg";


const importedImages = {
  "bohemian_livingroom": bohemian_livingroom,
  "country_livingroom": country_livingroom,
  "industrial_livingroom": industrial_livingroom,
  "minimalist_livingroom": minimalist_livingroom,
  "modern_livingroom": modern_livingroom,
  "rustic_livingroom": rustic_livingroom,
  "scandinavian_livingroom": scandinavian_livingroom,
  "traditional_livingroom": traditional_livingroom,

  "modern_sittingroom": modern_sittingroom,
  "minimalist_sittingroom": minimalist_sittingroom,
  "industrial_sittingroom": industrial_sittingroom,
  "scandinavian_sittingroom": scandinavian_sittingroom,
  "traditional_sittingroom": traditional_sittingroom,
  "country_sittingroom": country_sittingroom,
  "bohemian_sittingroom": bohemian_sittingroom,
  "rustic_sittingroom": rustic_sittingroom,

  "modern_kitchen": modern_kitchen,
  "minimalist_kitchen": minimalist_kitchen,
  "industrial_kitchen": industrial_kitchen,
  "scandinavian_kitchen": scandinavian_kitchen,
  "traditional_kitchen": traditional_kitchen,
  "country_kitchen": country_kitchen,
  "bohemian_kitchen": bohemian_kitchen,
  "rustic_kitchen": rustic_kitchen,

  "modern_bedroom": modern_bedroom,
  "minimalist_bedroom": minimalist_bedroom,
  "industrial_bedroom": industrial_bedroom,
  "scandinavian_bedroom": scandinavian_bedroom,
  "traditional_bedroom": traditional_bedroom,
  "country_bedroom": country_bedroom,
  "bohemian_bedroom": bohemian_bedroom,
  "rustic_bedroom": rustic_bedroom,

  "modern_childroom": modern_childroom,
  "minimalist_chilroom": minimalist_childroom,
  "industrial_childroom": industrial_childroom,
  "scandinavian_childroom": scandinavian_childroom,
  "traditional_childroom": traditional_childroom,
  "country_childroom": country_childroom,
  "bohemian_childroom": bohemian_childroom,
  "rustic_childroom": rustic_childroom,

  "modern_toilet": modern_toilet,
  "minimalist_toilet": minimalist_toilet,
  "industrial_toilet": industrial_toilet,
  "scandinavian_toilet": scandinavian_toilet,
  "traditional_toilet": traditional_toilet,
  "country_toilet": country_toilet,
  "bohemian_toilet": bohemian_toilet,
  "rustic_toilet": rustic_toilet,

  "modern_terrace": modern_terrace,
  "minimalist_terrace": minimalist_terrace,
  "industrial_terrace": industrial_terrace,
  "scandinavian_terrace": scandinavian_terrace,
  "traditional_terrace": traditional_terrace,
  "country_terrace": country_terrace,
  "bohemian_terrace": bohemian_terrace,
  "rustic_terrace": rustic_terrace,
};

const Quiz = () => {
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [scores, setScores] = useState({
    modern: 0,
    minimalist: 0,
    industrial: 0,
    scandinavian: 0,
    traditional: 0,
    country: 0,
    bohemian: 0,
    rustic: 0,
  });

  const categories = [
    {
      questionName: t("stylequizPageCategoriesNameHall"),
      categoryImagesName: [
        "modern_livingroom",
        "minimalist_livingroom",
        "industrial_livingroom",
        "scandinavian_livingroom",
        "traditional_livingroom",
        "country_livingroom",
        "bohemian_livingroom",
        "rustic_livingroom",
      ]
    },
    {
      questionName: t("stylequizPageCategoriesNameSittingRoom"),
      categoryImagesName: [
        "modern_sittingroom",
        "minimalist_sittingroom",
        "industrial_sittingroom",
        "scandinavian_sittingroom",
        "traditional_sittingroom",
        "country_sittingroom",
        "bohemian_sittingroom",
        "rustic_sittingroom",
      ]
    },
    {
      questionName: t("stylequizPageCategoriesNameKitchen"),
      categoryImagesName: [
        "modern_kitchen",
        "minimalist_kitchen",
        "industrial_kitchen",
        "scandinavian_kitchen",
        "traditional_kitchen",
        "country_kitchen",
        "bohemian_kitchen",
        "rustic_kitchen",
      ]
    }, {
      questionName: t("stylequizPageCategoriesNameBedroom"),
      categoryImagesName: [
        "modern_bedroom",
        "minimalist_bedroom",
        "industrial_bedroom",
        "scandinavian_bedroom",
        "traditional_bedroom",
        "country_bedroom",
        "bohemian_bedroom",
        "rustic_bedroom",
      ]
    },
    {
      questionName: t("stylequizPageCategoriesNameChildroom"),
      categoryImagesName: [
        "modern_childroom",
        "minimalist_chilroom",
        "industrial_childroom",
        "scandinavian_childroom",
        "traditional_childroom",
        "country_childroom",
        "bohemian_childroom",
        "rustic_childroom",
      ]
    },
    {
      questionName: t("stylequizPageCategoriesNameToilet"),
      categoryImagesName: [
        "modern_toilet",
        "minimalist_toilet",
        "industrial_toilet",
        "scandinavian_toilet",
        "traditional_toilet",
        "country_toilet",
        "bohemian_toilet",
        "rustic_toilet",
      ]
    },
    {
      questionName: t("stylequizPageCategoriesNameTerrace"),
      categoryImagesName: [
        "modern_terrace",
        "minimalist_terrace",
        "industrial_terrace",
        "scandinavian_terrace",
        "traditional_terrace",
        "country_terrace",
        "bohemian_terrace",
        "rustic_terrace",
      ]
    },
  ];


  const [winnerScores, setWinnerScores] = useState();

  const winnerMessage = (() => {
    const imgStyle = { objectFit: "cover" };
    if (winnerScores === "bohemian") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleBohem")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescBohem")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={imgStyle} width={"100%"} height={"100%"} src={quizResultBohemImg} alt="bohemian" />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br /><span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultBohemStartTitle")}</span></h1>
          </div>

          <h1>{t("styleDesignQuizResultWhatTitleBohem")}</h1>
          <p>{t("styleDesignQuizResultWhatDescBohem")}</p>

          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_sittingroom} alt="bohemian_sittingroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_terrace} alt="bohemian_terrace" />
          </Row>

          <h1>{t("styleDesignQuizResultFurnitureTitleBohem")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescBohem")}</p>

          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_livingroom} alt="bohemian_livingroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_childroom} alt="bohemian_childroom" />
          </Row>

          <h1>{t("styleDesignQuizResultColorTitleBohem")}</h1>
          <p>{t("styleDesignQuizResultColorDescBohem")}</p>

          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_bedroom} alt="bohemian_bedroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={bohemian_toilet} alt="bohemian_toilet" />
          </Row>
        </div>

      );
    }
    else if (winnerScores === "country") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleCountry")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescCountry")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={imgStyle} width={"100%"} height={"100%"} src={quizResultCountryImg} alt="country" />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br /><span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultCountryStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleCountry")}</h1>
          <p>{t("styleDesignQuizResultWhatDescCountry")}</p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={country_sittingroom} alt="country_sittingroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={country_terrace} alt="country_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleCountry")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescCountry")}</p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultFurnitureDescLi1Country")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi2Country")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi3Country")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi4Country")}</li>
          </ul>
          <p>{t("styleDesignQuizResultFurnitureDesc2Country")}</p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={country_livingroom} alt="country_livingroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={country_childroom} alt="country_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleCountry")}</h1>
          <p>{t("styleDesignQuizResultColorDescCountry1")}</p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultColorDescLi1Country")}</li>
            <li>{t("styleDesignQuizResultColorDescLi2Country")}</li>
            <li>{t("styleDesignQuizResultColorDescLi3Country")}</li>
            <li>{t("styleDesignQuizResultColorDescLi4Country")}</li>
            <li>{t("styleDesignQuizResultColorDescLi5Country")}</li>
          </ul>
          <p>{t("styleDesignQuizResultColorDesc2Country")}</p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' style={imgStyle} src={country_bedroom} alt="country_bedroom" /> <LazyLoadImage className='col-md-6' style={imgStyle} src={country_toilet} alt="country_toilet" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "industrial") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleIndustrial")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescIndustrial")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} src={quizResultIndustrialImg} alt='iç mimari endüstriyel kapak fotoğrafı' />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")} <br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultIndustrialStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleIndustrial")}</h1>
          <p>{t("styleDesignQuizResultWhatDescIndustrial")}
            <br />
            <br />
            {t("styleDesignQuizResultWhatDesc2Industrial")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={industrial_sittingroom} alt="industrial_sittingroom" /> <LazyLoadImage className='col-md-6' src={industrial_terrace} alt="industrial_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleIndustrial")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescIndustrial")}
          </p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultFurnitureDescLi1Industrial")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi2Industrial")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi3Industrial")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi4Industrial")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi5Industrial")}</li>
          </ul>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={industrial_livingroom} alt="industrial_livingroom" /> <LazyLoadImage className='col-md-6' src={industrial_childroom} alt="industrial_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleIndustrial")}</h1>
          <p>{t("styleDesignQuizResultColorDescIndustrial")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={industrial_bedroom} alt="industrial_bedroom" /> <LazyLoadImage className='col-md-6' src={industrial_kitchen} alt="industrial_kitchen" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "minimalist") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleMinimalist")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescMinimalist")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} alt='iç mimari minimalist kapak fotoğrafı' src={quizResultMinimalistImg} />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultMinimalistStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleMinimalist")}</h1>
          <p>{t("styleDesignQuizResultWhatDescMinimalist")}
            <br />
            <br />
            {t("styleDesignQuizResultWhatDesc2Minimalist")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={minimalist_sittingroom} alt="minimalist_sittingroom" /> <LazyLoadImage className='col-md-6' src={minimalist_terrace} alt="minimalist_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleMinimalist")}</h1>
          <p>
            {t("styleDesignQuizResultFurnitureDescMinimalist")}
          </p>
          <div className='mt-3'></div>
          <p>{t("styleDesignQuizResultFurnitureDesc2Minimalist")}</p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultFurnitureDescLi1Minimalist")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi2Minimalist")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi3Minimalist")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi4Minimalist")}</li>
            <li>{t("styleDesignQuizResultFurnitureDescLi5Minimalist")}</li>
          </ul>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={minimalist_livingroom} alt="minimalist_livingroom" /> <LazyLoadImage className='col-md-6' src={minimalist_childroom} alt="minimalist_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleMinimalist")}</h1>
          <p>{t("styleDesignQuizResultColorDescMinimalist")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={minimalist_bedroom} alt="minimalist_bedroom" /> <LazyLoadImage className='col-md-6' src={minimalist_toilet} alt="minimalist_toilet" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "modern") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleModern")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescModern")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage alt='iç mimari modern kapak fotoğrafı' style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} src={quizResultModernImg} />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultModernStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleModern")}</h1>
          <p>{t("styleDesignQuizResultWhatDescModern")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={modern_sittingroom} alt="modern_sittingroom" /> <LazyLoadImage className='col-md-6' src={modern_terrace} alt="modern_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleModern")}</h1>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultFurnitureDescLi1Modern")}
            </li>
            <li>{t("styleDesignQuizResultFurnitureDescLi2Modern")}
            </li>
            <li>{t("styleDesignQuizResultFurnitureDescLi3Modern")}
            </li>
            <li>{t("styleDesignQuizResultFurnitureDescLi4Modern")}
            </li>
          </ul>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={modern_livingroom} alt="modern_livingroom" /> <LazyLoadImage className='col-md-6' src={modern_childroom} alt="modern_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleModern")}</h1>
          <p>{t("styleDesignQuizResultColorDescModern")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={modern_bedroom} alt="modern_bedroom" /> <LazyLoadImage className='col-md-6' src={modern_toilet} alt="modern_toilet" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "rustic") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleRustic")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescRustic")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} src={rustic_toilet} alt="rustic_toilet" />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultRusticStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleRustic")}</h1>
          <p>{t("styleDesignQuizResultWhatDescRustic")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={rustic_sittingroom} alt="rustic_sittingroom" /> <LazyLoadImage className='col-md-6' src={rustic_terrace} alt="rustic_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleRustic")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescRustic")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={rustic_livingroom} alt="rustic_livingroom" /> <LazyLoadImage className='col-md-6' src={rustic_childroom} alt="rustic_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleRustic")}</h1>
          <p>{t("styleDesignQuizResultColorDescRustic")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={rustic_bedroom} alt="rustic_bedroom" /> <LazyLoadImage className='col-md-6' src={rustic_toilet} alt="rustic_toilet" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "scandinavian") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleScandinavian")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescScandinavian")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} src={quizResultScandinavianImg} alt="quizResultScandinavianImg" />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")}<br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultScandinavianStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleScandinavian")}</h1>
          <p>{t("styleDesignQuizResultWhatDescScandinavian")}
          </p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultWhatDescLi1Scandinavian")}</li>
            <li>{t("styleDesignQuizResultWhatDescLi2Scandinavian")}</li>
            <li>{t("styleDesignQuizResultWhatDescLi3Scandinavian")}</li>
            <li>{t("styleDesignQuizResultWhatDescLi4Scandinavian")}</li>
            <li>{t("styleDesignQuizResultWhatDescLi5Scandinavian")}</li>
            <li>{t("styleDesignQuizResultWhatDescLi6Scandinavian")}</li>
          </ul>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={scandinavian_sittingroom} alt="scandinavian_sittingroom" /> <LazyLoadImage className='col-md-6' src={scandinavian_terrace} alt="scandinavian_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleScandinavian")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescScandinavian")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={scandinavian_livingroom} alt="scandinavian_livingroom" /> <LazyLoadImage className='col-md-6' src={scandinavian_childroom} alt="scandinavian_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleScandinavian")}</h1>
          <p>{t("styleDesignQuizResultColorDescScandinavian")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={scandinavian_bedroom} alt="scandinavian_bedroom" /> <LazyLoadImage className='col-md-6' src={scandinavian_toilet} alt="scandinavian_toilet" />
          </Row>
        </div>
      );
    }
    else if (winnerScores === "traditional") {
      return (
        <div className='mt-5'>
          <HelmetProvider>
            <Helmet>
              <title>{t("styleDesignQuizResultHelmetTitleTraditional")}</title>
              <meta name="description" content={t("styleDesignQuizResultHelmetDescTraditional")}></meta>
            </Helmet>
          </HelmetProvider>
          <div className='mb-5 styleDesignQuizResultStartDiv'>
            <LazyLoadImage style={{ objectFit: "cover", filter: "brightness(0.5)" }} width={"100%"} height={"100%"} src={quizResultTraditionalImg} alt="quizResultTraditionalImg" />
            <h1 className='styleDesignQuizResultStartTitle'>{t("styleDesignQuizResultStartTitle")} <br />
              <span style={{ fontWeight: "400" }}>{t("styleDesignQuizResultTraditionalStartTitle2")}</span></h1>
          </div>
          <h1>{t("styleDesignQuizResultWhatTitleTraditional")}</h1>
          <p>{t("styleDesignQuizResultWhatDescTraditional")}
          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={traditional_sittingroom} alt="traditional_sittingroom" /> <LazyLoadImage className='col-md-6' src={traditional_terrace} alt="traditional_terrace" />
          </Row>
          <h1>{t("styleDesignQuizResultFurnitureTitleTraditional")}</h1>
          <p>{t("styleDesignQuizResultFurnitureDescTraditional")}

          </p>
          <ul style={{ textAlign: "left" }}>
            <li>{t("styleDesignQuizResultFurnitureDescLi1Traditional")}
            </li>
            <li>{t("styleDesignQuizResultFurnitureDescLi2Traditional")}

            </li>
            <li>{t("styleDesignQuizResultFurnitureDescLi3Traditional")}

            </li>
          </ul>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={traditional_livingroom} alt="traditional_livingroom" /> <LazyLoadImage className='col-md-6' src={traditional_childroom} alt="traditional_childroom" />
          </Row>
          <h1>{t("styleDesignQuizResultColorTitleTraditional")}</h1>
          <p>{t("styleDesignQuizResultColorDescTraditional")}

          </p>
          <Row className='mb-5'>
            <LazyLoadImage className='col-md-6' src={traditional_bedroom} alt="traditional_bedroom" /> <LazyLoadImage className='col-md-6' src={traditional_toilet} alt="traditional_toilet" />
          </Row>
        </div>
      );
    }
  })



  const handleAnswer = (imageName) => {
    // Resim adından kategoriyi belirle
    const category = imageName.substring(0, imageName.indexOf('_'));

    // İlgili kategorinin puanını artır
    setScores(prevScores => ({
      ...prevScores,
      [category]: prevScores[category] + 5,
    }));

    // Bir sonraki soruya geç
    setCurrentQuestion(currentQuestion + 1);

    // Sayfanın en üstüne kaydır
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Scores nesnesinin değerlerini alıp bir diziye dönüştürme
    const scoresArray = Object.values(scores);

    // En büyük puanı bulma
    const maxScore = Math.max(...scoresArray);

    // En büyük puanın hangi kategoride olduğunu bulma
    const maxCategory = Object.keys(scores).find(key => scores[key] === maxScore);

    setWinnerScores(maxCategory);

  }, [scores])

  return (
    <div className='quizPageQuizArea'>
      <HelmetProvider>
        <Helmet>
          <title>{t("stylequizPageQuizHelmetTitle")}</title>
          <meta name="description" content={t("stylequizPageQuizHelmetDesc")}></meta>
        </Helmet>
      </HelmetProvider>


      <Container>
        <Row style={{ justifyContent: "center" }}>
          {currentQuestion < categories.length ? (
            <>
              <h1 className='mb-3 mt-3'>{categories[currentQuestion].questionName}</h1>
              <Row className='styleDesignQuizRow'>
                {categories[currentQuestion].categoryImagesName.map((imageName, index) => (

                  <Col sm={12} md={6} lg={4} key={index} style={{ paddingBottom: "20px" }}>
                    <LazyLoadImage onClick={() => handleAnswer(imageName)} width={"100%"} height={"100%"} style={{ cursor: "pointer", objectFit: "cover" }} src={importedImages[imageName]} alt={imageName} />
                  </Col>


                ))}
              </Row>

            </>
          ) : (
            <div>
              {winnerMessage()}
              <div className="container resume-section">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center mb-5">
            <h2 style={{ color: "black" }} className="heading-section">{t("contactPageContactFormH1")}</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrapper">
              <div className="row no-gutters">
                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch contactFormContactArea">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    <h3 className="mb-4">{t("contactPageContactFormMessageText")}</h3>
                    <div id="form-message-warning" className="mb-4"></div>
                    <form action="https://submit-form.com/30ifuachh" method="POST" id="contactForm" name="contactForm" className="contactForm" noValidate="novalidate">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label" htmlFor="name">{t("contactPageContactFormName")}</label>
                            <input type="text" className="form-control" name="name" id="name" placeholder={t("contactPageContactFormName")} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="label" htmlFor="email">{t("contactPageContactFormEmail")}</label>
                            <input type="email" className="form-control" name="email" id="email" placeholder={t("contactPageContactFormEmail")} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="subject">{t("contactPageContactFormPhone")}</label>
                            <input type="text" className="form-control" name="phone" id="subject" placeholder={t("contactPageContactFormPhone")} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label" htmlFor="#">{t("contactPageContactFormMessage")}</label>
                            <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder={t("contactPageContactFormMessage")}></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input type="submit" value={t("contactPageContactFormSendButton")} className="btn btn-primary contactFormSendButton" />
                            <div className="submitting"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                  <div className="contactFormInfoArea info-wrap w-100 p-md-5 p-4">
                    <h3>{t("contactPageContactFormInfoAreaH1")}</h3>
                    <p className="mb-4">{t("contactPageContactFormInfoAreaMessage")}</p>
                    <div className="dbox w-100 d-flex align-items-start">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaMapMarkedAlt size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaAddressSpan")}</span> Kızılırmak, 06510 Çankaya/Ankara</p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaPhoneAlt size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaPhoneNoSpan")}</span> <a href="tel://05526447143">0552-644-7143</a></p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <FaMailBulk size="20px"/>
                      </div>
                      <div className="text pl-3">
                        <p><span>{t("contactPageContactFormInfoAreaEmailSpan")}</span> <a href="mailto:melinteriordesign@outlook.com">melinteriordesign@outlook.com</a></p>
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center">
                      <div className="w-100 icon d-flex align-items-center justify-content-evenly">
                      <a href="https://www.instagram.com/melinteriordesign_?igsh=dmtncWVlaGd6Z2pz" className="me-4 text-reset" target='_blank' rel='noreferrer'>
                      <FaInstagram className="contactFormInfoIcon instagramIcon" />
                      </a>
                      <a href="https://tr.pinterest.com/melinteriordesignstudio/" className="me-4 text-reset" target='_blank' rel='noreferrer'>
                      <FaPinterest className="contactFormInfoIcon pinterestIcon" />
                      </a>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12241.578356511134!2d32.808903!3d39.910185!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d348b2a8a8bb1b%3A0x6fc94f4a1deecb8a!2sYDA%20Center!5e0!3m2!1str!2str!4v1709886136597!5m2!1str!2str"
              width="100%" height="450" title="Melinteriordesign Konum Harita">
            </iframe>
          </Col>
        </Row>

      </div>
            </div>


          )}
        </Row>
      </Container>


    </div>
  );
};

export default Quiz;